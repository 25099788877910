.search-bar-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
    height: auto;
  }
  
  .search-input {
    padding: 15px;
    border: 2px solid black;
    border-radius: 13px;
    font-size: 16px;
    width: 1000px;
    
  }

  .minsky-text {
    position: absolute;
    top: -24px;
    left: 100px;
    font-size: 13px;
    color: #555;
    font-weight: 900;
    font-family: "Major Mono Display", monospace;
  }
  
  
  
  .css-dev-only-do-not-override-1qfezbu .close-button.ant-btn {
    border: none; 
  }
  
  .css-dev-only-do-not-override-1qfezbu .close-button.ant-btn > .anticon {
    border: none; 
  }
  